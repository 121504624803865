// Models
import { IBaseState } from 'lib/redux/models'
import { IPaginatedResponse } from 'models/api'

export enum EExerciseActionTypes {
  CLEANUP = 'EXERCISE/CLEANUP',
  FAILURE = 'EXERCISE/FAILURE',
  FULFILL = 'EXERCISE/FULFILL',
  REQUEST = 'EXERCISE/REQUEST',
  SUCCESS = 'EXERCISE/SUCCESS',
  GET_EXERCISES = 'EXERCISE/GET_EXERCISE',
  GET_MORE_EXERCISES = 'EXERCISE/GET_MORE_EXERCISE',
  SUCCESS_LOAD_MORE = 'EXERCISE/SUCCESS_LOAD_MORE',
}

export interface IExerciseState extends IBaseState {
  exercises?: IExerciseListResponse
}

export interface IExerciseListPayload {
  muscle?: string
  name?: string
  body_part?: string | string[]
  page?: number
  page_size?: number
}

export interface IExerciseListResponse extends IPaginatedResponse {
  results: IExerciseData[]
}

export interface IExerciseData {
  id: number
  name: string
  file?: string
  muscle: string
  body_part: string
  equipment: string
  thumbnail?: string
}
